import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './components/Main.component';

import './App.css';

function App() {
  return (
      <div className="App">
        <div className="topMenu">
          <div style={{display: 'inline-block',marginTop:0}}><h5>CamgirlCrawler</h5></div>
          <div style={{display: 'inline-block',marginTop:0}}>
          <picture>
            <source media="(max-width: 600px)" srcSet="images/logo_sm.gif" width={'61px'} height={'63px'}  />
            <source media="(min-width: 600px)" srcSet="images/logo.gif" width={'81px'} height={'84px'} />
            <img src="images/logo.gif" width={'81px'} height={'84px'}  alt="CamgirlCrawler is a face recognition based cam girl model search engine"/>
          </picture>
          </div>         
        </div>
        <section>
            <div className='pageHeader'>
              <h1>Cam girl face search engine</h1>
              <div><b><i>Find your favourite cam girl model again!</i></b></div>
            </div>
            <div className='info'>
                To find your favourite cam girl model again or even similar looking cam girls simply upload an image of her face and press 'Start Search'. 
                For best results use a cropped full frontal face image of just her face exluding any distractions or additional faces in the background. 
                We use the latest AI enable face recognition libraries to return the most accurate face matches to the girl you are searching for.
                We also regularly crawl the most popular cam girl sites in order to find the faces of new girls and models so you can find your favourite 
          </div>
        </section>
        <Main /> 
      </div>
  );
}
export default App;