import { IFace } from '../@types/Face';


export const IsFace = (value: IFace): value is IFace => {
  if(value === undefined)
        return false;
      
        if (value.name !== undefined && value.thumbnailUrl !== undefined)
          return true
        else
          return false;
      }
