import {IFace } from '../@types/Face';
import { useEffect,useState } from 'react';

type Props = {
  face: IFace;
};

const FaceComponent: React.FC<Props> = ({ face }) => {

  const [currentPersonUrl, setPersonUrl] = useState('');
  const [currentThumbnailUrl, setThumbnailUrl] = useState('');
  const [lastSeenstr, seLastSeenstr] = useState('');
  const [siteICO, setSiteICO] = useState('');

  useEffect(()=>{
    seLastSeenstr(date_TO_String(new Date(face.lastSeen)));
    setThumbnailUrl(face.thumbnailUrl);
    setPersonUrl(face.personUrl);
    setSiteICO('images/' + face.siteCode + '.ico');
}, []); // empty array means only once

  const handleImageError = () => {
    setThumbnailUrl('images/unknown.gif');
   }  

   function date_TO_String(date_Object: Date): string {
    // get the year, month, date, hours, and minutes seprately and append to the string.
    let date_String: string =
      date_Object.getDate() +
      "/" +
      (date_Object.getMonth() + 1) +
      "/" +
      +date_Object.getFullYear();

    return date_String;
  }

  function openInNewTab(url:string){
    window.open(url, "_blank", "noreferrer");
  };

return (
  
    <div className="results">
      <div  role="link" onClick={() => openInNewTab(currentPersonUrl)}>
            <div className='thumb'>
              <div className='thumbInner'>
                <img  onError={handleImageError} src={`${currentThumbnailUrl}`}  width={150} height={150}/>
              </div>     
            </div>
            <div>              
              <span><img src={`${siteICO}`} alt={`${face.site}`} title={`${face.site}`}  width={16} height={16}/>&nbsp;{face.name}&nbsp;{face.score}%</span><br/>
              <span>Last seen: {lastSeenstr}</span>
            </div>  
    </div>
  </div>
  );
}
export default FaceComponent;