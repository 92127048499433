import axios from "axios";
const https = require('https');
/* const axiosInstance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      }),
    }); */
const selfCerthttpsAgent = new https.Agent({
  rejectUnauthorized: false
});

export default class UploadService {
 
    private static instance: UploadService;
  
    baseUrl: string;
    private constructor() { 
  
      const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
      this.baseUrl = baseUrl! + '/image/upload';
    }
  
    public static getInstance(): UploadService {
      if (!UploadService.instance) {
        UploadService.instance = new UploadService();
      }
      return UploadService.instance;
    }


 uploadFile = (file: any, onUploadProgress: any): Promise<any> => {
  let formData = new FormData();

  formData.append("formfile", file);


/*   return axiosInstance.post(this.baseUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  }).then((response) => {
        return response.data;
  })
  .catch((error) => {
    return error.response;
  }); */
 // axios.defaults.httpsAgent = httpsAgent;
  return axios.post(this.baseUrl, formData, {
    httpsAgent: selfCerthttpsAgent,
    headers: {
      "Content-Type": "multipart/form-data",
      "API_KEY": process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_PROD_KEY : process.env.REACT_APP_API_DEV_KEY
    },
    onUploadProgress,
  }).then((response) => {
        return response.data;
  })
  .catch((error) => {
    return error.response;
  });

};
/*     uploadFile(image:any):Promise<IFace[]> { 
        let formData = new FormData();
        formData.append("file", image);
        // formData.append("otherStuff", "stuff from a text input");
        return new Promise<IFace[]>((resolve, reject) => {
                  fetch(this.baseUrl, 
                    {
                        method: 'POST',
                        mode: 'cors',
                        headers: {'Content-Type': 'multipart/form-data'},                      
                        body: formData
                    }).then((response) => {
                        if (response.ok) {
                            return response.json();
                        }else{
                            return response.status;
                        }
                    }).then(data => {
                        resolve(data);
                    }).catch(error => 
                        reject(error.message)
                    ); 
            });
        } */
}