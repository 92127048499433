import * as React from 'react';
import { useEffect,useState,useRef } from 'react';
import Dropzone from "react-dropzone";
import {IFace } from '../@types/Face';
import UploadService from '../services/upload.service';
import FaceComponent from '../components/FaceComponent';
import {IsFace} from '../helpers/checkIsType';
import { handleHttpError } from '../helpers/HttpErrorHandler';
import CookieConsent from "react-cookie-consent";
import {Modal, Button} from 'react-bootstrap';
import { unchangedTextChangeRange } from 'typescript';

const Faces = () => {
    const myRef = useRef<null | HTMLDivElement>(null); 
    const [myDivPos, setmyDivPos] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [faces,popFaceData] = React.useState<IFace[]>([]);
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);

    //file upload
    const MAX_SIZE = 5242880;
    const [prevfile, setPrevFile] = useState<any>(undefined);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([])
    const [currentFile, setCurrentFile] = useState<any>(undefined);
    const [progress, setProgress] = useState(0);

    //modal
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true);

    //const faceService = FaceService.getInstance();
    const uploadService = UploadService.getInstance();

    useEffect(()=>{
      //document.title = "webcamspider.com";
      setProgress(0);
      if (myRef.current) {
        setmyDivPos(myRef.current.offsetTop);
    }
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
       return () => { if(prevfile!==undefined) URL.revokeObjectURL(prevfile.preview)};
       //setShow(true);      
  }, []); // empty array means only once

  //file upload
  const onDrop = (files:any) => {

    resetUpload();

    if (files.length > 0 && files[0].size > MAX_SIZE) {
      searchAgain();
      setErrorMsg(`file too big. Your file is ${files[0].size} KB, Max is ${MAX_SIZE} KB`);
      return;
    }

    if (files.length > 0) {
      setSelectedFiles(files);
      let currentFile = files[0];
      setCurrentFile(currentFile);
      setPrevFile(() => Object.assign(currentFile, {
        preview: URL.createObjectURL(currentFile)
      }
    ));
    }
  };

  const upload = () => {

    setLoading(true);

    uploadService.uploadFile(currentFile, (event:any) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    }).then(response => { 
      if(response.length >0 && IsFace(response[0])){
        popFaceData(response);
        }else{
          setErrorMsg(handleHttpError(response));
      } 
    }).catch(error => {
      setErrorMsg(handleHttpError(500));
      console.log(error)
    }).then(() =>{
          setLoading(false);
          setFileUploaded(true);
    });
  }

  const resetUpload = () => {
    setProgress(0);
    setErrorMsg("");
    setFileUploaded(false);
    popFaceData([]);
   }   
  const searchAgain = () => {
    window.scrollTo(0, myDivPos)
    resetUpload();
    setSelectedFiles([]);
    let currentFile = undefined;
    setCurrentFile(currentFile);
    //window.scrollTo(0, 0);
   }  

  function redirectAway(){
    window.location.replace('https://www.google.com')
  };
  function openInNewTab(url:string){
    window.open(url, "_blank", "noreferrer");
  };
 return (
  <div>
    {!fileUploaded && (
      <div ref={myRef}>
        {selectedFiles[0] ? (
            <div onClick={searchAgain} style={{maxWidth: '800px',margin:'auto',textAlign:'right'}}>
              <img src={'images/reload.png'} alt="Reload" title='Reload' width={'25px'} style={{cursor:'pointer'}}/>
          </div> 
          ):(
            <div style={{maxWidth: '800px',height:'25px',margin:'auto'}}></div>
          )}
      <Dropzone onDrop={onDrop} multiple={false} onFileDialogOpen={resetUpload} onDragOver={resetUpload}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {selectedFiles[0] && selectedFiles[0].name ? (
                <div>          
                    {prevfile && (
                      <div className='dropzone-thumbsOutter'>
                        <div className='dropzone-thumbsContainer'>
                          <div className='dropzone-thumb'>
                            <div className='dropzone-thumbInner'>
                              <img
                                  src={prevfile.preview} alt='Preview'
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}         
                  <div className="selected-file">
                    {selectedFiles && selectedFiles[0].name}
                  </div>
                </div>
              ) : (
                <div className='dropzone-file-types'>
                  <div>Drop face image here to upload (.jpg, .png, .gif, .webp)</div>
                  <br/>
                  or
                  <br/><br/>
                </div>
              )}
              {currentFile ===undefined && (
                <div>
                  <div>
                    <button className="btn btn-success" onClick={onDrop}>
                          Browse for face image
                        </button>
                    </div>
                  <div className='dropzone-file-size'>
                    <div>Max face image file size: 5MB</div>
                  </div>
                </div>
              )}
            </div>
            <div style={{display: 'inline-block'}}>
              <aside className="file-selected-wrapper">
                {currentFile && (
                <button
                  className="btn btn-success"
                  disabled={loading}
                  onClick={upload}
                >
                  Start Search
                </button>
                )}
              </aside>
            </div>
            <div style={{display: 'inline-block'}}>{loading && (                       
                  <img src={'images/spinner.gif'} alt="Loading"  width={'25px'}/>
                  )}
            </div>          
          </section>
        )}
      </Dropzone>
    </div>
    )}

      {loading && (
        <div className="progress mx-auto mt-3" style={{width: "75%"}}>
          <div
            className="progress-bar progress-bar-info progress-bar-striped"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}
        <div>
        {fileUploaded && (
          <div>
            <div><br/><h3>Search results for image:</h3></div>
          </div>
        )}
        {!fileUploaded && errorMsg && (
              <div className="alert alert-danger message" role="alert">
                {errorMsg}
              </div>
         )}
        {fileUploaded && (
          <div>
                      <div className='resultsThumbsOutter'>
                        <div className='thumbsContainer'>
                          <div className='thumb'>
                            <div className='thumbInner'>
                              <img
                                  src={prevfile.preview}
                                  // Revoke data uri after image is loaded
                                  onLoad={() => { URL.revokeObjectURL(prevfile.preview) }} alt='Preview'
                                />
                            </div>
                          </div>
                        </div>
                      </div>
             {!errorMsg && faces.length>0 &&  (
              <div className="resultsMessage">
                {faces.length} possible matches found:
              </div>
              )}
             {errorMsg && (
              <div className="alert alert-danger errorMessage" role="alert">
                {errorMsg}
              </div>
              )}
            <div className='resultsThumbsOutter'>
              {!errorMsg && faces.length>0 &&  faces.map((face: IFace) => (
                <FaceComponent key={face.name}  face={face} />
                    ))    
                } 
            </div>
          </div>
        )}

        {fileUploaded && (
            <div style={{margin:'30px'}}>
            <button
                  className="btn btn-success"
                  onClick={searchAgain}>
                   Search again
                </button>
            </div> 
            )} 
          </div> 
          <div className='info'>
                For more info on getting the best facial recognition results see our<br/>
                'Cam girl facial recognition search guide' at the foot this page.
          </div>   
          <div style={{marginTop: "50px"}}>
          <section>
          <h2>Popular cam girl searches:</h2>
          <div className='resultsThumbsOutter' style={{marginBottom: '100px'}}>
            <div className="results" style={{display: 'inline-block'}}>
                <div  role="link" onClick={() => openInNewTab('https://www.livejasmin.com/en/chat/CintyaLeinner')}>
                      <div className='thumb'>
                        <div className='thumbInner'>
                          <img src={`${'images/CintyaLeinner/igHpTm4fXEQtcGATmHOiBblp+O6JVsoi7ytskst43fqIAO4e.jpg'}`} alt='Cintya Leinner' title='Cintya Leinner' width={150} height={150}/>
                        </div>     
                      </div>
                      <div>
                        <span>Cintya Leinner</span>
                      </div>  
                </div>
            </div>
            <div className="results" style={{display: 'inline-block'}}>
                <div  role="link" onClick={() => openInNewTab('https://www.livejasmin.com/en/chat/ArianaSantana')}>
                      <div className='thumb'>
                        <div className='thumbInner'>
                          <img src={`${'images/ArianaSantana/IFFAsiJPFxbHYofUX+xYhOflehNpYHkPUptDgZb19CGjO+P+.jpg'}`} alt='Ariana Santana' title='Ariana Santana' width={150} height={150}/>
                        </div>     
                      </div>
                      <div>
                        <span>Ariana Santana</span>
                      </div>  
                </div>
            </div>
            <div className="results" style={{display: 'inline-block'}}>
                <div  role="link" onClick={() => openInNewTab('https://www.livejasmin.com/en/chat/MiaPersy')}>
                      <div className='thumb'>
                        <div className='thumbInner'>
                        <img src={`${'images/MiaPersy/szODfJqCDEUrLlaJxIKXmWA7jPrS1nim3vKuF8dYcm1Sq7g3.jpg'}`} alt='Mia Persy' title='Mia Persy' width={150} height={150}/>
                        </div>     
                      </div>
                      <div>         
                        <span>Mia Persy</span>
                      </div>  
                </div>
            </div>
            <div className="results" style={{display: 'inline-block'}}>
                <div  role="link" onClick={() => openInNewTab('https://www.livejasmin.com/en/chat/GabyPastori')}>
                      <div className='thumb'>
                        <div className='thumbInner'>
                          <img src={`${'images/GabyPastori/FN7OgItZHlDXO7wny2HkwafAWG1vkUj9yjiE+HmUvKpc4ckS.jpg'}`} alt='Gaby Pastori' title='Gaby Pastori' width={150} height={150}/>
                        </div>     
                      </div>
                      <div>
                        <span>Gaby Pastori</span>
                      </div>  
                </div>
            </div>
            <div className="results" style={{display: 'inline-block'}}>
                <div  role="link" onClick={() => openInNewTab('https://www.livejasmin.com/en/chat/JessicaPortman')}>
                      <div className='thumb'>
                        <div className='thumbInner'>
                          <img src={`${'images/JessicaPortman/xJpLH2K8B0OlGJqrE7ZIv0rEMIdi5jjqgtGzRJIGg9YnoAwK.jpg'}`}  alt='Jessica Portman' title='Jessica Portman' width={150} height={150}/>
                        </div>     
                      </div>
                      <div>
                        <span>Jessica Portman</span>
                      </div>  
                </div>
            </div>
            <div className="results" style={{display: 'inline-block'}}>
                <div  role="link" onClick={() => openInNewTab('https://www.livejasmin.com/en/chat/GeorgiaBotero')}>
                      <div className='thumb'>
                        <div className='thumbInner'>
                          <img src={`${'images/GeorgiaBotero/KXduaPGdTJmgWEY5ftZSlQxz1VRjkkhb6wzxMHDNT3sCOXTR.jpg'}`} alt='Georgia Botero' title='Georgia Botero' width={150} height={150}/>
                        </div>     
                      </div>
                      <div>
                        <span>Georgia Botero</span>
                      </div>  
                </div>
            </div>
          </div>
          </section>
        </div>
        <div className='info'>
          <section>
          <h2>Cam girl facial recognition search guide</h2>
          <br/>
          <p>To get the best cam girl search results try to use the best quality full face image you have for the cam girl you are searching for. Avoid images that have more than one face in them.
          Try to use an image where the face is facing forward and square on to the camera. Try to use the most up to date image you have for your girl's face in it. Avoid using side on or side profile face images of your girl. 
          These are very unlikely to produce any facial recognition face matches. Avoid using images where the girl's face is a very small part of the image. Find an image of your girl with the
          largest highest quality full frontal face you have.</p>
          </section>
            <div>Problems?<br/> 
              <p><a href="mailto:admin@camgirlcrawler.com">admin@camgirlcrawler.com</a></p>
            </div>
          </div>
      <Modal show={show} className='modal-lg' aria-label="Sexual Content Warning!" aria-labelledby='scw'>
        <Modal.Header>
            <Modal.Title>Sexual Content Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{textAlign:'center'}}>
          <p  id='scw' >This web page may include images that may contain sexually explicit material<br/>that might not be suitable or offensive for some viewers<br/>
          <br/>
          <b>Enter ONLY if you are at least 18 years old</b>
          </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-danger' onClick={handleClose}>I accept and I am at least 18 years old</Button>
          <Button className='"btn btn-warning' onClick={redirectAway}>Nope, get me out of here!</Button>
        </Modal.Footer>
        </Modal>
    </div>
  );
};

  export default Faces;